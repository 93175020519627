<div class="wrapper">
    <div class="content-wrapper">
        <div class="text-wrapper">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum nostrum porro placeat, hic asperiores molestias ratione maiores quaerat, nihil, non eum alias!
        </div>
        <div class="button-wrapper">
            <button mat-raised-button color="primary" routerLink="main/login" routerLinkActive="active">
                Login
            </button>
        </div>
    </div>
</div>