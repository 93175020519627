import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageContainerComponent } from './landing-page-container/landing-page-container.component';
import { AppToolbarModule } from '../toolbar/toolbar.module';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    LandingPageContainerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    AppToolbarModule
  ]
})
export class LandingPageModule { }
