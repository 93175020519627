import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageContainerComponent } from './landing-page/landing-page-container/landing-page-container.component';

export const routes: Routes = [
    {
        path: '', component: LandingPageContainerComponent
    },
    {
        path: 'main',
        loadChildren: () => import('./main/main.module')
            .then(m => m.MainModule)
    },
    {
        path: 'business',
        loadChildren: () => import('./business/business.module')
            .then(m => m.BusinessModule)
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        initialNavigation: 'enabledNonBlocking'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
