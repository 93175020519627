import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

import { AuthenticationService, BaseService, SharedService } from '../_services/_index';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router,
                private authenticationService: AuthenticationService, 
                private baseService: BaseService, 
                private sharedService: SharedService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            // fix timeout
            // timeout(60000),
            timeout(90000),
            catchError(err => {
            this.sharedService.changeActivePreloaderStatus(false);
            if (err.status === 401) {
                if([...this.baseService.parseUrl(err.url)].pop() != 'generate-token'){
                    this.sharedService.changeIsSessionExpired(true);
                    this.authenticationService.logout();
                    this.router.navigate(['/main/login']);
                }else{
                    this.sharedService.changeIsSessionExpired(true);
                    this.authenticationService.logout();
                    this.router.navigate(['/main/login']);
                }
                // auto logout if 401 response returned from api
            }else if(err.status === 0){
                console.log("Connection refused: " + err.status);
                return throwError("Connection refused");
            }
            // const error = err.error.message || err.statusText;
            // return throwError(error);
            // TODO
            return throwError(err);
        }))
    }
}