import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig } from '../_configs/_index';


@Injectable()
export class AppJwtInterceptor implements HttpInterceptor {

    private readonly appMode: boolean = AppConfig.settings.debug;
   
    constructor(private cookieService: CookieService ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        let currentUserCookie = this.cookieService.get('currentUser');
        if (currentUserCookie) {
            let currentUser = JSON.parse(currentUserCookie);

            if(this.appMode){
                console.log("AppJwtInterceptor", currentUser);
            }	

            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }
        return next.handle(request);
    }
}