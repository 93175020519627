/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

import { EnvironmentLoader as environmentLoaderPromise } from './app/_environments/_environment.loader';

import { AppModule } from './app/app.module';
import { environment } from './app/_environments/environment';

// Load env config dynamic
environmentLoaderPromise.then(env => {
    if (env.production) {
        enableProdMode();
    }

    // Load env
    environment.envType = env.envType;
    environment.env = env.env;
    environment.production = env.production;
    environment.debug = env.debug;
    environment.rootPath = env.rootPath;
    environment.webSocketProtocol = env.webSocketProtocol;
    environment.webServiceProtocol = env.webServiceProtocol;
    environment.webServiceDomain = env.webServiceDomain;
    environment.webServicePort = env.webServicePort;
    environment.webServiceContextPath = env.webServiceContextPath;

    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
});