// Main - Angular
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';

import { AppRoutingModule } from './app-routing.module';
import { AppJwtInterceptor, ErrorInterceptor } from './_helpers/_index';



// Services
import { CookieService } from 'ngx-cookie-service';

// Main Components 
import { AppConfig } from './_configs/_index';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { LandingPageModule } from './landing-page';
import { AppToolbarModule } from './toolbar/toolbar.module';

export function initializeApp(appConfig: AppConfig) {
    //appConfig = class, load = function from class
    return () => appConfig.load();
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        LandingPageModule,
        HttpClientModule,
        AppToolbarModule
    ],
    providers: [
        AppConfig,
        provideAnimationsAsync(),
        CookieService,
        JwtHelperService,
        // { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AppJwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
