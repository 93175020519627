import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-page-container',
  templateUrl: './landing-page-container.component.html',
  styleUrl: './landing-page-container.component.scss'
})
export class LandingPageContainerComponent {
  public testFunction(){
    console.log('test func!');
  }
}
